import React from "react"
import { handleKey } from "../js/utils"
import ReactHtmlParser from "react-html-parser"
import SEO from "../components/seo"
import Layout from "../components/layout"
import work from "../js/work-content"
class Work extends React.Component {
  render() {
    let workcontent = work
    console.log(work)
    return (
      <Layout>
        <SEO title="Danton Nguyen: Work Experience" />
        <article className="work">
          <h1 className="title title--col2">current and past work</h1>
          {workcontent.map(({ title, writeup, date, tech, links, image }) => (
            <section className="work__section " key={handleKey()}>
              <figure className="work__figure">
                {!image ? (
                  ""
                ) : (
                  <img className="work__img" src={image.src} alt={image.alt} />
                )}
              </figure>
              <div className="work__details">
                <header>
                  <h2 className="work__title">{title}</h2>
                </header>
                <section className="work__writeup">
                  {ReactHtmlParser(writeup)}
                </section>
                <section className="work__techStack">
                  <h3 className="techStack__heading">Project Date</h3>

                  <ul className="techStack__list techStack__list--single-line ">
                    <li className="techStack__item">
                      {" "}
                      <time>{date}</time>
                    </li>
                  </ul>
                </section>
                <section className="work__techStack">
                  <h3 className="techStack__heading">Tech Stack</h3>
                  <ul className="techStack__list">
                    {tech.map(tech => (
                      <li className="techStack__item" key={handleKey()}>
                        {tech}
                      </li>
                    ))}
                  </ul>
                </section>
                {!links ? (
                  ""
                ) : (
                  <section className="work__techStack">
                    <h3 className="techStack__heading">Link</h3>
                    <ul className="techStack__list">
                      {links.map(link => {
                        return (
                          <li key={link.url} className="techStack__item">
                            <a
                              title={link.title}
                              href={link.url}
                              rel={link.rel ? link.rel : ""}
                            >
                              {link.name}
                            </a>
                          </li>
                        )
                      })}
                    </ul>
                  </section>
                )}
              </div>
            </section>
          ))}
        </article>
      </Layout>
    )
  }
}

export default Work
