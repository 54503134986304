let work = [
    {
        title: "WorkJam Frontend Web Developer",
        writeup: `<ul> <li>Working mainly on company SaaS products built using React on the frontend.</li>
        <li>Develop client facing forms and features in small teams.</li>
        <li>Building out internal component libraries to be used within our software.</li>
        <li>Working directly with backend developers to plan and groom features</li>
        </ul>
        `,
        date: "2021.08.01 - Present",
        tech: ["React", "Redux", "Redux Saga", "Styled Components", "Google Looker", "Storybook", "GraphQL"],
        links: [
            {
                name: "WorkJam",
                title: "Visit WorkJam Company Site",
                url: "https://www.workjam.com/",
            },
        ],
    },
    {
        title: "PressCentric Software Engineer",
        writeup: `<ul> <li>Working mainly on company SaaS products built using React on the frontend.</li>
    <li>Taking charge of implementing several features into production web apps and developing prototypes for management.</li>
    <li>Maintaining project libraries, resolving dependency semver issues, including webpack configuration, and dependency updates.</li>
    <li>Writing tools for PressCentric CMS CSS theming for internal use, in addition to responsive CSS theming for CMS frontend. </li>
    <li>Working with backend engineers for consumption of API data in SaaS products.</li>
    </ul>
    `,
        date: "2019.08.01 - Present",
        tech: ["React", "Redux", "SCSS", "SASS", "CSS", "Fabric JS"],
        links: [
            {
                name: "PressCentric",
                title: "Visit PressCentric Company Site",
                url: "https://www.presscentric.com/",
            },
        ],
    },
    {
        title: "Cassette",
        writeup: `<p>Portfolio project - Webapp designed to allow friends to collaboratively create a song playlist on Spotify. Meant to solve the questions arising at parties "What song is this?", "Whos the artist?", "Can I play a song next?".</p>
    <p>Currently working on the desktop view, best views on mobile right now. In addition a visual revamp for a better UX.</p><p>Demo available on Heroku linked below, its slow to start since its hosted on Heroku. Requires your Spotify credentials to login.</p>`,
        date: "2018.12.00 - Present",
        tech: [
            "React",
            "Redux",
            "SCSS",
            "SASS",
            "Gulp",
            "Spotify Web API",
            "Spotify Web SDK",
            "Web Audio API",
        ],
        links: [
            {
                name: "Github",
                title: "Link to Project",
                url: "https://github.com/nuwen/dnguyen",
            },
            {
                name: "Heroku",
                title: "Link to demo",
                url: "https://cassette-dn.herokuapp.com/",
            },
        ],
    },
    {
        title: "dantonnguyen",
        writeup: `Currently working on adding contact form, and SEO.`,
        date: "2018.12.00 - Present",
        tech: ["React", "Parcel", "Gulp", "SCSS", "BrowserSync"],
    },
    {
        title: "The Jibe: Agency Work",
        writeup: `<p>
    Recently adopting Shopify as one of their services the Jibe offered I was one of the first of a few developers to start on liquid at this shop. My roll here involved extensive work with translating Photoshop files and InVision into web pages in several media sizes. Some projects required building up from a basic Shopify store skeleton theme developed in house and some required adapting a prebuilt or bought theme and customizing it to the client's needs.</p>
    <p>Being at an agency focused on e-commerce I was exposed to other CMS platforms such as Lemonstand which utlizes Twig as their templating language, since my first project at the shop was on Lemonstand, I was tasked strictly to work with CSS styling.</p>
    <p>Another CMS I worked with while here is from Hubspot, another developer and I were tasked with building a multipage site for an existing client that mirrored the same theme and branding as their e-commerce store. This was a fairly challenge task as Hubspots CMS requires you to use a on site UI to create elements and CSS styling.</p>
    <p>Due to the nature of it being an agency, I do not have access to any code and would like to respect their clients. However feel free to ask for a private viewing.</p>
  `,
        date: "2016-2018",
        tech: [
            "jQuery",
            "SASS",
            "Grunt",
            "GulpJS",
            "Shopify Liquid",
            "Lemonstand Twig",
            "Hubspot CMS",
            "Drupal",
        ],
        links: [
            {
                name: "Agency Site",
                title: "Link to agency website.",
                url: "https://thejibe.com/",
                rel: "nofollow",
            },
        ],
    },
    {
        title: "CodeClass",
        writeup: `Code Class Web app inspired by Khan Academy built by four students at Lighthouse Labs. Code class was built with the intention of allowing programming instructors to live code within a browser environment but able to compile on the fly.</p>
    <p>Built on top of that were powerful learning tools for students, as student were able to login to virtual classrooms and copy, edit, view code written by instructors. Students would be able to rewind back during the coding session and replay exactly how the instructor was coding (not a video) thanks to Cloud 9's Ace Editor library. The application was able to work with C, Java, Javascript, and Ruby at deadline. All code compiled by clients were ran in a virtual box to prevent any malicious and dangerous code from affecting our servers.</p>`,
        date: "2017.02.28 - 2017.02.31",
        tech: [
            "node",
            "React",
            "Webpack",
            "Redux",
            "jQuery",
            "SASS",
            "AWS",
            "Web Sockets",
            "Knex",
            "oAuth",
            "Express",
        ],
        links: [
            {
                name: "Github Repo",
                title: "Link to CodeClass Github Repository",
                url: "https://github.com/nuwen/codeclass",
            },
        ],
    },
]
export default work;

// {
//   title: "",
//   writeup: ``,
//   date: "",
//   tech: [""],
//   links: [{
//     name: "",
//     title: "",
//     url: ""
//   }]
// }
